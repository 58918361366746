import React from 'react'
import '../styles/Footer.css'

function Footer() {
  return (
    <div className='footer-container'>
      <span>developed by sejo basic</span>
      <span>2023</span>
    </div>
  )
}

export default Footer
